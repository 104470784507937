import ReactGA from "react-ga";
export const config = {
  apiKey: "AIzaSyDsmWxFexrd890dUvpA313qvxvcjDRp1Ok",
  authDomain: "wowbridge88.firebaseapp.com",
  databaseURL: "https://wowbridge88.firebaseio.com",
  projectId: "wowbridge88",
  storageBucket: "wowbridge88.appspot.com",
  messagingSenderId: "862342003288"
};

export const initializeReactGA = () => {
  ReactGA.initialize("UA-128346813-1");
  ReactGA.pageview("/");
};
